'use strict';

window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/newsletterCheckout'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/miniCartEmptyShow'));
    processInclude(require('./components/popUpNewsLetter'));
    processInclude(require('./components/popUpPromotions'));
    processInclude(require('./components/validationsNewsletter'));
    processInclude(require('./components/mobileHeader'));
    processInclude(require('./components/headerDomActions'));
    processInclude(require('./components/popUpProductsRecommendation'));
    processInclude(require('./components/popUpBikeSizeChart'));
    processInclude(require('./components/imageGrid'));
    processInclude(require('./product/variationAttributeSize'));
    processInclude(require('./product/quickView'));
    processInclude(require('./dataLayers'));
    processInclude(require('wishlist/product/wishlistHeart'));
    processInclude(require('base/components/search'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('./profile/profile'));
    processInclude(require('./cvspage.js'));
});

require('base/thirdParty/bootstrap');
require('base/components/spinner');
