'use strict';
// Close modal PopUpRecommendations
$('.closePopUpRecommendation, .button-recommended').on('click', function () {
    $('.modal-recommendation').addClass('d-none');
});

$(window).on('load', function () {
    setTimeout(() => {
        $('.modal-recommendation').css('opacity', '1');
    }, 1000);
});

var productContainers = $('.product-quickview.product-detail');
var selectedItemsLabel = $('.selected-items-label');

// Select the product and change the button text
$('.select-container-recommendation').on('click', function () {
    $(this).parent().closest('.product-quickview.product-detail').addClass('selected');
    var selectedContainers = [];
    var currentRecommendationContainer = $(this).parent().closest('.product-quickview.product-detail');
    productContainers.each(function () {
        if ($(this).hasClass('selected')) {
            selectedContainers.push($(this));
        }
    });
    productContainers.each(function (index) {
        if ($(this).hasClass('slick-cloned')) {
            productContainers.splice(index, 1);
        }
    });
    var selectContainerRecommendation = $(this);
    var selectedProductsContainer = $('.selected-recommended-products-container');
    var productCurrentContainer = $(this).parent().closest('.product-quickview.product-detail');

    let productBox = $(`<div class='productBoxCSS' data-pid='${currentRecommendationContainer.attr('data-pid')}'></div>`);
    var productInnerText = currentRecommendationContainer.find('.product-name.name-recommendation').text();
    var productName = $(`<p class='recommended-product-row'>${productInnerText}</p>`);
    var xmark = $("<i class='fa fa-times fa-times-recommendation' aria-hidden='true'></i>");
    productBox.append(productName);
    productBox.append(xmark);
    selectedProductsContainer.append(productBox);

    xmark.on('click', function () {
        $(this).parent().closest('.productBoxCSS').remove();
        if (productCurrentContainer.hasClass('selected')) {
            productCurrentContainer.removeClass('selected');
            selectContainerRecommendation.prop('disabled', false);
        }
        var selectedContainersUpdate = [];
        productContainers.each(function () {
            if ($(this).hasClass('selected')) {
                selectedContainersUpdate.push($(this));
            }
        });
        if (selectedContainersUpdate.length > 0) {
            selectedItemsLabel.text(`${selectedContainersUpdate.length} de ${productContainers.length} seleccionados`);
            // Add border bottom
            $('.slick-dots').css({ 'border-bottom': '1px solid #CACACA' });
            $('.button-add-recommended').prop('disabled', false);
        } else {
            // Remove border bottom
            $('.slick-dots').css({ 'border-bottom': '' });
            $('.button-add-recommended').prop('disabled', true);
            selectedItemsLabel.empty();
        }
    });
    $(this).prop('disabled', true);
    if (selectedContainers.length > 0) {
        selectedItemsLabel.text(`${selectedContainers.length} de ${productContainers.length} productos seleccionados:`);
        $('.button-add-recommended').prop('disabled', false);
        // Add border bottom
        $('.slick-dots').css({ 'border-bottom': '1px solid #CACACA' });
    } else {
        $('.button-add-recommended').prop('disabled', true);
        // Remove border bottom
        $('.slick-dots').css({ 'border-bottom': '' });
        selectedItemsLabel.empty();
    }
});

var variationAttributesDiv = productContainers.find('.variation-attribute-size-values');

variationAttributesDiv.each(function () {
    var liVariationElements = $(this).find('.variation-attribute-size');
    if (liVariationElements.length === 1) {
        $(this).closest('.select-container-recommendation');
        $(this).closest('.product-quickview.product-detail').find('.select-container-recommendation').prop('disabled', false);
    }
});

var arrowSlider = $('span[data-arrow-slider]').attr('data-arrow-slider');
var arrowSliderR = $('span[data-arrow-slider-r]').attr('data-arrow-slider-r');
// Recommended Products Slick
$('.body-multiple-products').slick({
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: "<img class='a-left control-c prev slick-prev slick-prev-reco' src='" + arrowSlider + "'>",
    nextArrow: "<img class='a-right control-c next slick-next slick-next-reco' src='" + arrowSliderR + "'>"
});

$('.variation-attribute-size-values > .variation-attribute-size').on('click', function () {
    var selectedContainers = [];
    productContainers.each(function () {
        if ($(this).hasClass('selected')) {
            selectedContainers.push($(this));
        }
    });
});

// Hide modal Product Recommendation
function hidePopupRecommendation() {
    $('.modal-recommendation').addClass('d-none');
}
// Show modal Product Recommendation
function showPopupRecommendation() {
    $('.modal-recommendation').removeClass('d-none');
}

// Save the current date local storage
$('.closePopUpRecommendation, .button-recommended').on(
    'click',
    function saveCurrentDateRecommendation() {
        let now = new Date();
        localStorage.setItem('lastVisitDateRecommendation', now);
    }
);

// Check if 24 hours have passed
function checkDate() {
    let lastVisitDateRecommendation = localStorage.getItem(
        'lastVisitDateRecommendation'
    );

    if (lastVisitDateRecommendation) {
        let now = new Date();
        let diff = now - new Date(lastVisitDateRecommendation);
        let hours = diff / 1000 / 60 / 60;
        if (hours >= 2) {
            // 2 hours or more have passed
            showPopupRecommendation('.modal-recommendation');
        } else {
            // 2 hours have not passed
            hidePopupRecommendation('.modal-recommendation');
        }
    }
}

$(document).ready(function () {
    checkDate();
});
