'use strict';

/* NewsLetter Mobile or Desktop at Checkout */
function screenSize() {
    if (window.screen.width >= 769) {
        $(".mobile_newsLetter").remove();
    } else {
        $(".desktop_newsLetter").remove();
    }
}

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    if ($('.email-signup-message').length === 0) {
        $('body').append(
            '<div class="email-signup-message"></div>'
        );
    }
    $('.email-signup-message')
        .append('<div class="email-signup-alert text-center ' + status + '">' + data.msg + '</div>');

    setTimeout(function () {
        $('.email-signup-message').remove();
        button.removeAttr('disabled');
    }, 3000);
}

/** User remove NewsLetter */
function suscriberNegation() {
    $('#userNegation').click(function () {
        $(".container_newsletter_checkout").remove();
    });
}

/** Enable/Disable Suscriber btn */
function eventListenerValidationsWpCheckout() {
    $('#NewsLetter-btn-checkout').prop('disabled', true);
    $("#whatsappCheckout, #smsCheckout").on('click', function () {
        if ($('#whatsappCheckout').is(":checked") || $('#smsCheckout').is(":checked") ) {
            $('#NewsLetter-btn-checkout').prop('disabled', false);
        } else {
            $('#NewsLetter-btn-checkout').prop('disabled', true);
        }
    });
}

function newsLetterCheckout() {
    $('#NewsLetter-btn-checkout').on('click', function (e) {
        e.preventDefault();
        var url = $(this).data('href');
        var button = $(this);
        var emailId = $('input[name=email-checkout]').val();
        var phoneId = $(".shipping-phone").text().trim();
        var smsId = $('#smsCheckout').is(":checked");
        var whatsappId = $('#whatsappCheckout').is(":checked");

        $.spinner().start();
        $(this).attr('disabled', true);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                emailId: emailId,
                phoneId: phoneId,
                smsId: smsId,
                whatsappId: whatsappId
            },
            success: function (data) {
                displayMessage(data, button);
                $(".container_newsletter_checkout").remove();
            },
            error: function (err) {
                displayMessage(err, button);
            }
        });
    });
}


module.exports =  {
    screenSize: screenSize,
    eventListenerValidationsWpCheckout: eventListenerValidationsWpCheckout,
    newsLetterCheckout: newsLetterCheckout,
    suscriberNegation:suscriberNegation
}
