'use strict';

let emailValidation = false;
let phoneValidation = false;
let whatsAppValidation = false;
let SMSValidation = false;

const regexEmail = /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
const regexPhone = /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/;

/**
 * Habilita el botón de suscripción mostrándolo.
 *
 * @function
 * @name enableSubscribeButton
 * @author Eduardo González
 * @date 21/07/2023
 * @returns {void}
 */
function enableSubscribeButton() {
    $('.subscribe-email').prop('disabled', false);
}

/**
 * Deshabilita el botón de suscripción ocultándolo.
 *
 * @function
 * @name disableSubscribeButton
 * @author Eduardo González
 * @date 21/07/2023
 * @returns {void}
 */
function disableSubscribeButton() {
    $('.subscribe-email').prop('disabled', true);
}

/**
 * Valida el estado del correo electrónico y realiza acciones correspondientes en función de su valor.
 * Si el correo electrónico es válido, oculta el mensaje de correo electrónico inválido y verifica el estado del teléfono.
 * Si el correo electrónico es inválido, muestra el mensaje de correo electrónico inválido y deshabilita el botón de suscripción.
 *
 * @function
 * @name validateEmail
 * @returns {void}
 */
function validateEmail() {
    const isEmailValid = emailValidation;
    const $invalidEmailElement = $('.invalid__email-home');
    const isPhoneEmpty = $('#NewsLetterHomePhone').val().length === 0;

    if (isPhoneEmpty) {
        phoneValidation = undefined;
    }

    if (isEmailValid) {
        $invalidEmailElement.addClass('d-none');

        const isPhoneValid = phoneValidation === true;
        const isPhoneUndefined = phoneValidation === undefined;

        // Verificar las condiciones para habilitar o deshabilitar el botón de suscripción
        if (((whatsAppValidation || SMSValidation) && isPhoneValid) || (!(whatsAppValidation || SMSValidation) && (isPhoneUndefined || isPhoneValid))) {
            enableSubscribeButton();
        } else {
            disableSubscribeButton();
        }
    } else {
        // Si el correo electrónico no es válido, mostrar el mensaje de correo electrónico inválido
        $invalidEmailElement.removeClass('d-none');
        disableSubscribeButton();
    }
}

/**
 * Valida el estado del teléfono y realiza acciones correspondientes en función de su valor.
 * Si el teléfono es válido, verifica el estado de la validación del correo electrónico.
 * Si el teléfono es inválido, realiza validaciones adicionales para habilitar o deshabilitar el botón de suscripción.
 *
 * @function
 * @name validatePhone
 * @returns {void}
 */
function validatePhone() {
    const isPhoneValid = phoneValidation;
    const phoneEmpty = $('#NewsLetterHomePhone').val().length === 0;
    const $invalidPhoneElement = $('.invalid__phone-home');

    if (isPhoneValid) {
        $invalidPhoneElement.addClass('d-none');

        if (emailValidation) {
            enableSubscribeButton();
        }
    } else {
        // Caso teléfono inválido
        if (!isPhoneValid && phoneEmpty && whatsAppValidation) {
            $invalidPhoneElement.removeClass('d-none');
            disableSubscribeButton();
            return;
        } else if (phoneEmpty) {
            $invalidPhoneElement.addClass('d-none');
        } else {
            $invalidPhoneElement.removeClass('d-none');
        }

        if (emailValidation && phoneEmpty && !whatsAppValidation) {
            enableSubscribeButton();
        } else {
            disableSubscribeButton();
        }
    }
}

/**
 * Valida el estado del checkbox WhatsApp y realiza acciones correspondientes en función de su valor.
 * Si WhatsApp está activado, verifica el estado de la validación del teléfono y del correo electrónico.
 * Si WhatsApp está desactivado, realiza validaciones adicionales para habilitar o deshabilitar el botón de suscripción.
 *
 * @function
 * @name validateWhatsApp
 * @author Eduardo González
 * @date 21/07/2023
 * @returns {void}
 */
function validateWhatsApp() {
    const $invalidPhoneElement = $('.invalid__phone-home');
 
    if ($('#whatsappCheckHome').prop('checked') || $('#smsCheckHome').prop('checked')) {
        // Caso WhatsApp activado
        if (!phoneValidation) {
            $invalidPhoneElement.removeClass('d-none');
            disableSubscribeButton();
        } else {
            $invalidPhoneElement.addClass('d-none');

            if (emailValidation) {
                enableSubscribeButton();
            }
        }
    } else { // Caso WhatsApp desactivado
        const isPhoneInvalid = !phoneValidation;
        const isPhoneEmpty = $('#NewsLetterHomePhone').val().length === 0;
        const isPhoneNotEmpty = $('#NewsLetterHomePhone').val().length !== 0;
        const isPhoneUndefined = phoneValidation === undefined;
        const isEmailInvalid = emailValidation === false;

        if ((isPhoneInvalid && isEmailInvalid) || (isEmailInvalid && isPhoneEmpty)
            || (emailValidation && isPhoneInvalid && isPhoneEmpty) || (emailValidation && isPhoneUndefined)) {
            $invalidPhoneElement.addClass('d-none');
        }

        if (emailValidation && (phoneValidation || isPhoneEmpty)) {
            enableSubscribeButton();
        } else if (isPhoneInvalid && isPhoneNotEmpty) {
            $invalidPhoneElement.removeClass('d-none');
        }
    }
}

/**
 * Maneja el evento de cambio de entrada de correo electrónico.
 * Verifica si el correo electrónico es válido utilizando una expresión regular y actualiza la variable emailValidation.
 * Luego, llama a la función validateEmail() para realizar validaciones adicionales.
 *
 * @function
 * @name handleEmailInputChange
 * @author Eduardo González
 * @date 21/07/2023
 * @returns {void}
 */
function handleEmailInputChange() {
    const emailValue = $(this).val();
    emailValidation = regexEmail.test(emailValue);
    validateEmail();
}

/**
 * Maneja el evento de cambio de entrada de teléfono.
 * Verifica si el teléfono es válido utilizando una expresión regular y actualiza la variable phoneValidation.
 * Luego, llama a la función validatePhone() para realizar validaciones adicionales.
 *
 * @function
 * @name handlePhoneInputChange
 * @author Eduardo González
 * @date 21/07/2023
 * @returns {void}
 */
function handlePhoneInputChange() {
    const phoneValue = $(this).val();
    phoneValidation = regexPhone.test(phoneValue);
    validatePhone();
}

/**
 * Maneja el evento de cambio de entrada de teléfono.
 * Verifica si el checkbox está tildado o no y actualiza la variable whatsAppValidation.
 * Luego, llama a la función validateWhatsApp() para realizar validaciones adicionales.
 *
 * @function
 * @name handleWhatsappInputChange
 * @author Eduardo González
 * @date 21/07/2023
 * @returns {void}
 */
function handleWhatsappInputChange() {
    whatsAppValidation = $(this).prop('checked');
    validateWhatsApp();
}

/**
 * Maneja el evento de cambio de entrada de teléfono.
 * Verifica si el checkbox está tildado o no y actualiza la variable SMSValidation.
 * Luego, llama a la función validateSMS() para realizar validaciones adicionales.
 *
 * @function
 * @name handleSMSInputChange
 * @author Cesar Hernanadez
 * @date 31/07/2023
 * @returns {void}
 */
function handleSMSInputChange() {
    SMSValidation = $(this).prop('checked');
    validateWhatsApp();
}


/**
 * Agrega los event listeners para validar los campos del formulario de suscripción en la página de inicio de NewsLetter.
 * Deshabilita el botón de suscripción inicialmente.
 *
 * @function
 * @name eventListenerValidationsFormHomeNewsLetter
 * @returns {void}
 */
function eventListenerValidationsFormHomeNewsLetter() {
    disableSubscribeButton();

    const $inputEmail = $('#NewsLetterHome');
    const $inputPhone = $('#NewsLetterHomePhone');
    const $inputWhatsapp = $('#whatsappCheckHome');
    const $inputSMS = $('#smsCheckHome');

    $inputEmail.on('input', handleEmailInputChange);
    $inputPhone.on('input', handlePhoneInputChange);
    $inputWhatsapp.on('change', handleWhatsappInputChange);
    $inputSMS.on('change', handleSMSInputChange);
}

module.exports = {
    eventListenerValidationsFormHomeNewsLetter: eventListenerValidationsFormHomeNewsLetter
};
