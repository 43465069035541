'use strict';

var customUpdateMiniCart = true;
// This implementation is only for showing minicart despite is empty
// it follows a similar structure than SFRA miniCart.js
module.exports = function () {
    $('.minicart').on('click', function () {
        if ($('.search:visible').length === 0 && $(window).width() > 769) {
            return;
        }
        var url = $('.minicart').data('action-url');
        var currentUrl = $(location).prop('href');
        var isInCart = currentUrl.includes('carrito');
        if (isInCart) {
            location.reload();
        } else if (!customUpdateMiniCart) {
            $('.minicart .popover').addClass('show');
            return;
        }

        var count = parseInt($('.minicart .minicart-quantity').text(), 10);
        if (isInCart) {
            location.reload();
        } else if (count === 0 && $('.minicart .popover.show').length === 0) {
            $('.minicart .popover').addClass('show');
            $('.minicart .popover').spinner().start();
            $.get(url, function (data) {
                $('.minicart .popover').empty();
                $('.minicart .popover').append(data);
                customUpdateMiniCart = false;
                $.spinner().stop();
            });
        }
    });
    $('body').on('product:afterAddToCart', function () {
        customUpdateMiniCart = true;
    });
    $('body').on('cart:update', function () {
        customUpdateMiniCart = true;
    });
};
