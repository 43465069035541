'use strict';
var base = require('base/profile/profile');
var formValidation = require('base/components/formValidation');
function submitProfile() {
    var birthdayDay = document.getElementById('birthdayDay');
    var birthdayMonth = document.getElementById('birthdayMonth');
    var birthdayYear = document.getElementById('birthdayYear');
    var confirmEmail = document.getElementById('confirmEmail');
    if (birthdayDay.value) {
        birthdayDay.disabled = true;
        birthdayMonth.disabled = true;
        birthdayYear.disabled = true;
    }

    confirmEmail.onpaste = e => {
        e.preventDefault();
        return false;
    };

    $('form.edit-profile-form').submit(function (e) {
        var $form = $(this);
        e.preventDefault();
        var url = $form.attr('action');
        $form.spinner().start();
        $('form.edit-profile-form').trigger('profile:edit', e);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                $form.spinner().stop();
                if (!data.success) {
                    formValidation($form, data);
                } else {
                    location.href = data.redirectUrl;
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                $form.spinner().stop();
            }
        });
        return false;
    });
}

base.submitProfile = submitProfile;
module.exports = base;

