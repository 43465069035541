'use strict';
/* NOTAS PARA EL MODAL DE BORRAR PRODUCTO EN EL CARRITO:
- EL Z-INDEX DEL CONTAINER POPOVER-BOTTOM SIEMPRE ES 1 MÁS DE LO QUE PONGAS, LO QUE HACE QUE NUNCA SE VEA
- EL Z-INDEX ACTUAL ES DE 1060
- HAY QUE SACAR EL HIDDEN-XS-DOWN DE minicart-mobile-delete-button PARA QUE SE VEA EL POPUP EN MOBILE*/
var cart = require('../cart/cart');
var base = require('wishlist/components/miniCart');

var updateMiniCart = true;

function disableCheckForCartAddButtonsOnMinicartOpen() {
    // Get input elements and buttons
    var inputs = $('.quantityChangeMinicart');
    var subBtn = $('.sub-minicart');
    var addBtn = $('.add-minicart');

    // Get values and iterate through them
    var values = inputs.map(function () {
        // Get the value of the attribute for each input
        var min = $(this).attr('data-minimum');
        var max = $(this).attr('data-available');
        return {
            value: parseInt($(this).val(), 10),
            min: min,
            max: max
        };
    }).get();

    values.forEach(function (item, index) {
        if (item.value == item.min) {
            $(inputs[index]).prev(subBtn).addClass('btn-qty-disabled');
        }

        if (item.value == item.max) {
            $(inputs[index]).next(addBtn).addClass('btn-qty-disabled');
        }
    });
}

function addMinicartMobileBackogrund() {
    var windowWidth = $(window).width();

    $('.minicart-link, #minicart-desktop').on('touchstart click', function () {
        setTimeout(() => {
            if (windowWidth < 769 && $('.minicart .popover').hasClass('show')) {
                $('.minicart-mobile-background').removeClass('d-none');
            }
        }, 500);
    });

    $(document).on('touchstart click', 'button.minicart-close, .minicart-mobile-background', function () {
        if (windowWidth < 769) {
            setTimeout(() => {
                $('.minicart-mobile-background').addClass('d-none');
            }, 500);
        }
    });
}

module.exports = {
    base: function () {
        cart();
        addMinicartMobileBackogrund();
        $('.minicart').on('count:update', function (event, count) {
            if (count && $.isNumeric(count.quantityTotal)) {
                $('.minicart .minicart-quantity').text(count.quantityTotal);
                $('.minicart .minicart-link').attr({
                    'aria-label': count.minicartCountOfItems,
                    title: count.minicartCountOfItems
                });
            }
        });
        $('.minicart-close').on('touchstart click', function () {
            disableCheckForCartAddButtonsOnMinicartOpen();
        });
        $('.minicart-link, #minicart-desktop').on('touchstart click', function () {
            if ($('.search:visible').length === 0 && $(window).width() > 769) {
                return;
            }
            var url = $('.minicart').data('action-url');
            var count = parseInt($('.minicart .minicart-quantity').text(), 10);
            var currentUrl = $(location).prop('href');
            var isInCart = currentUrl.includes('carrito');
            if (isInCart) {
                location.reload();
            } else if (count !== 0 && $('.minicart .popover.show').length === 0) {
                if (!updateMiniCart) {
                    if (!$('.minicart .popover').hasClass('show')) {
                        $('.minicart .popover').addClass('show');
                        return;
                    }
                    $('.minicart .popover').removeClass('show');

                    return;
                }
                $('.minicart .popover').addClass('show');
                $('.minicart .popover').spinner().start();
                $.get(url, function (data) {
                    $('.minicart .popover').empty();
                    $('.minicart .popover').append(data);
                    updateMiniCart = false;
                    $.spinner().stop();
                    disableCheckForCartAddButtonsOnMinicartOpen();
                });
            }
        });

        $('body').on('touchstart click', function (e) {
            if ($('.minicart').has(e.target).length <= 0) {
                $('.minicart .popover').removeClass('show');
            }
        });

        $('.minicart').on('mouseleave focusout', function (event) {
            if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
                || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
                || $('body').hasClass('modal-open')) {
                event.stopPropagation();
                return;
            }

        });
        $('body').on('change', '.minicart .quantity', function () {
            if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
                location.reload();
            }
        });
        $('body').on('product:afterAddToCart', function () {
            updateMiniCart = true;
        });
        $('body').on('cart:update', function () {
            updateMiniCart = true;
        });
    },
    moveToWishlist: base.moveToWishlist
};
