$(document).ready(function () {
    $(window).on('load', function () {
        var cvSelectFile = $('#cv-select-file');
        var cvFormContainer = $('.cv-form-container');
        var formGroupProfile = $('.form-group-profile');
        var staffBtn = $('#staff-btn');
        var staffFormInfo = $('#staffFormInfo');
        var comercialBtn = $('#comercial-btn');
        var comercialFormInfo = $('#comercialFormInfo');
        var submitFormInput = $('.btn-send-cv-form');
        var cvFormName = $('#cv-form-name');
        var cvProfile = $('#cv-form-profile')[0];
        var cvFormSelectedOption = $('#cv-form-selected-option')[0];
        var fileSelectedName = $('.file-selected-name');
        var dropArea = $('#drop-area');
        var dragAndDropText = $('.drag-and-drop-text');
        var uploadIcon = $('.fa-upload-drag-and-drop');
        var maxSizeFile = 1 * 1024 * 1024;
        var windowSize = screen.width;

        staffBtn.on('click', function () {
            cvFormContainer.removeClass('d-none');
            formGroupProfile.removeClass('d-none');
            staffFormInfo.removeClass('d-none');
            comercialFormInfo.addClass('d-none');
            comercialBtn.css('opacity', '0.6');
            staffBtn.css('opacity', '1');

            if (cvProfile.required === false) {
                cvProfile.required = true;
            }
            cvFormSelectedOption.value = 'staff_cvs_uploaded';
        });

        comercialBtn.on('click', function () {
            cvFormContainer.removeClass('d-none');
            formGroupProfile.addClass('d-none');
            comercialFormInfo.removeClass('d-none');
            staffFormInfo.addClass('d-none');
            staffBtn.css('opacity', '0.6');
            comercialBtn.css('opacity', '1');

            if (cvProfile.required === true) {
                cvProfile.required = false;
            }
            cvFormSelectedOption.value = 'commercial_cvs_uploaded';
        });

        cvSelectFile.on('change', function (e) {
            var file = e.target.files[0];
            fileSelectedName[0].innerHTML = cvSelectFile[0].files[0].name;
            if (file.size > maxSizeFile) {
                fileSelectedName[0].innerHTML = 'Sin archivo seleccionado';
                cvSelectFile[0].value = '';
                alert('El archivo supera 1 MB de tamaño, por favor intente nuevamente.');
            }
            if (cvSelectFile[0].value !== '') {
                submitFormInput.prop('disabled', false);
            } else {
                submitFormInput.prop('disabled', true);
            }
        });

        dropArea.on('dragover', function (e) {
            e.preventDefault();
            $(this).addClass('dragging');
        });

        dropArea.on('dragleave', function (e) {
            e.preventDefault();
            $(this).removeClass('dragging');
        });

        dropArea.on('drop', function (e) {
            e.preventDefault();
            $(this).removeClass('dragging');
            var files = e.originalEvent.dataTransfer.files;
            if (files[0].type === 'application/msword' || files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || files[0].type === 'application/pdf' || files[0].type === 'image/jpeg') {
                if (files[0].size < maxSizeFile) {
                    fileSelectedName[0].innerHTML = files[0].name;
                    cvSelectFile[0].files = files;
                } else {
                    alert('El archivo supera 1 MB de tamaño, por favor intente nuevamente.');
                }
            } else {
                alert('Por favor ingrese su archivo en los siguientes formatos: (doc, docx, pdf, jpg)');
            }

            if (cvSelectFile[0].value !== '') {
                submitFormInput.prop('disabled', false);
            } else {
                submitFormInput.prop('disabled', true);
            }
        });

        $('.cv-form').submit(function (event) {
            event.preventDefault();

            $.ajax({
                url: $(this).attr('action'),
                type: $(this).attr('method'),
                data: new FormData(this),
                processData: false,
                contentType: false,
                success: function (response) {
                    if (response.success) {
                        window.location.href = response.redirectUrl;
                    } else {
                        alert(response.message);
                    }
                },
                error: function (xhr, status, error) {
                    alert('Hubo un error en la solicitud, Por favor intente de nuevo con un archivo que no supere 1 MB de tamaño');
                }
            });
        });

        if (windowSize < 576) {
            dropArea.css('border', 'none');
            dropArea.css('margin-top', '20px');
            dragAndDropText.css('display', 'none');
            uploadIcon.css('display', 'none');
        }
    });
});
