/**
 * DAB-2819 Pop up Newsletter in the sites
 **/
'use strict';

var email;
var startDate;
var statusPopup;
var timeOutId;
var emailValidation;
var phoneValidation;
var whatsAppValidation;
var SMSValidation;
const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const regexPhone = /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/;
/**
 * getEmailDecode: This method decodes customer´s email.
 **/

function getEmailDecode() {
    if (localStorage.getItem('email')) {
        email = Buffer.from(localStorage.getItem('email'), 'base64').toString(
            'utf-8'
        );
        return email;
    }
    return false;
}
/**
 * resetEmailCloseSession: This method takes care of always listen to the click event
 * of the id #Logout. With this, it will remove the email from localStorage.
 **/

function resetEmailCloseSession() {
    $('#Logout').click(function () {
        localStorage.removeItem('email');
    });
}

/**
 * triggerLogout: This method will trigger a user logout.
 **/

function triggerLogout() {
    localStorage.removeItem('email');
    $('#Logout')[0].click();
}

/**
 * inactiveLogout: This method will listen a signed up customer´s activity and will log it out after 10 minutes in home page.
 **/

function inactiveLogout() {
    if (email) {
        $(window).mousemove(function () {
            clearTimeout(timeOutId);
            timeOutId = setTimeout(function () {
                triggerLogout();
            }, 600000);
        });
    }
}

/**
 * appendToUrl: This method appends query params to a given URL.
 **/
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl +=
        (newUrl.indexOf('?') !== -1 ? '&' : '?') +
        Object.keys(params)
            .map(function (key) {
                return key + '=' + encodeURIComponent(params[key]);
            })
            .join('&');

    return newUrl;
}

/**
 * showPopup: This method executes button__newsletter for show popUp.
 **/
function showPopup() {
    document.getElementById('button__newsletter').style.display = 'active';
    $('#button__newsletter').trigger('click');
}

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    if ($('.email-signup-message').length === 0) {
        $('body').append('<div class="email-signup-message"></div>');
    }
    $('.email-signup-message').append(
        '<div class="email-signup-alert text-center ' +
            status +
            '">' +
            data.msg +
            '</div>'
    );

    setTimeout(function () {
        $('.email-signup-message').remove();
        button.removeAttr('disabled');
    }, 3000);
}

/**
 * subscribePopUp: This method subscribe the user email, when the user clicks button.
 **/
function subscribePopUp() {
    $('.subscribe-email-popUp').on('click', function (e) {
        e.preventDefault();
        var url = $(this).data('href');
        var button = $(this);
        var emailId = $('input[name=hpEmailSignUp]').val();
        var phoneId = $('input[name=hpPhoneSignUp]').val();
        var smsId = $('input[name=smsCheck]').is(':checked');
        var whatsappId = $('input[name=whatsappCheck]').is(':checked');
        $.spinner().start();
        $(this).attr('disabled', true);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                emailId: emailId,
                phoneId: phoneId,
                smsId: smsId,
                whatsappId: whatsappId
            },
            success: function (data) {
                $('#customerNegation').trigger('click');
                displayMessage(data, button);
            },
            error: function (err) {
                displayMessage(err, button);
            }
        });
    });
}

/**
 * noCustomerEndDate: This method removes localStorage startDate
 **/
function noCustomerEndDate() {
    localStorage.removeItem('startDate');
    return true;
}

/**
 * noCustomerDisablePopup: This method creates localStorage startDate.
 **/
function noCustomerDisablePopup() {
    startDate = Date.now();
    localStorage.setItem('startDate', startDate);
}

/**
 * openModalNewsLetter: This method will show up the popUp in the storefront.
 **/
function openModalNewsLetter() {
    if (statusPopup) {
        showPopup();
    }
}

/**
 * calculateDate: This method calculates the difference between the startDate and today, in order to display popUp or not.
 **/

function calculateDate() {
    var popDisableDays = parseInt(
        document.getElementById('popDisableDays').value,
        10
    );
    var today = Date.now();
    var daysCalculated;
    if (popDisableDays >= 1) {
        daysCalculated =
            Math.round(today - parseInt(startDate, 10)) / (1000 * 60 * 60 * 24);
        statusPopup =
            Math.floor(daysCalculated) > popDisableDays
                ? noCustomerEndDate()
                : false;
        openModalNewsLetter();
    }
    return false;
}

/**
 *   @param CustomerEmail: String get of the localStorage.
 * customerDisablePopup: This method consults the method X of the controller Y which set startDate in database customer data.
 **/

function customerDisablePopup(customerEmail) {
    var url = document.getElementById('StatusUpdate').value;
    var urlParams = { customerEmail };
    url = appendToUrl(url, urlParams);
    $.ajax({
        type: 'get',
        url: url,
        dataType: 'json',
        success: function (data) {
            if (data.error) {
                data.error = 'error';
            } else {
                return data.msg;
            }
        },
        error: function (err) {}
    });
}

/**
 *   @param CustomerEmail: String get of the localStorage.
 * customerValidationGetStatusPopup: This method consults the method X of the controller Y, which
 * should return the status of popUp.
 **/

function customerValidationGetStatusPopup(customerEmail) {
    inactiveLogout();
    var url = document.getElementById('statusPopup').value;
    var urlParams = {
        customerEmail
    };
    url = appendToUrl(url, urlParams);
    $.ajax({
        type: 'get',
        url: url,
        dataType: 'json',
        success: function (data) {
            if (data.error) {
                data.error = 'error';
            } else {
                statusPopup = data.popupStatus;
                openModalNewsLetter();
            }
        },
        error: function (err) {
            console.log(err);
        }
    });
}

/**
 * noCustomerValidation: This method will consult the localstorage startDate. If it exists, it would
 * execute calculateDate(). If not, return true.
 **/

function noCustomerValidation() {
    startDate = localStorage.getItem('startDate');
    if (startDate) {
        return calculateDate();
    }
    statusPopup = true;
    openModalNewsLetter();
}

/**
 * validationGetStatusPopup: This method has a conditional that validates if customer Validation Get Status Popup()
 * is registered and if NocustomerValidation() is not registered, depending on the response it executes the popUp.
 **/
function validationGetStatusPopup() {
    email = getEmailDecode();
    if (email) customerValidationGetStatusPopup(email);
    else noCustomerValidation();
}

/**
 * customerNegation: This method will listen click on negation button of newsletter in popUp and will execute a disable.
 **/

function customerNegation() {
    $('#customerNegation').click(function () {
        email = getEmailDecode();
        email ? customerDisablePopup(email) : noCustomerDisablePopup();
    });
}


/**
 * Habilita el botón de suscripción mostrándolo.
 *
 * @function
 * @name enableSubscribeButton
 * @author Eduardo González
 * @date 21/07/2023
 * @returns {void}
 */
function enableSubscribeButton() {
    $('.subscribe-email-popUp').prop('disabled', false);
}

/**
 * Deshabilita el botón de suscripción ocultándolo.
 *
 * @function
 * @name disableSubscribeButton
 * @author Eduardo González
 * @date 21/07/2023
 * @returns {void}
 */
function disableSubscribeButton() {
    $('.subscribe-email-popUp').prop('disabled', true);
}

/**
 * Valida el correo electrónico ingresado en el NewsLetter PopUp.
 * Si el correo electrónico es válido, oculta el mensaje de correo electrónico inválido.
 * Si el correo electrónico es inválido, muestra el mensaje de correo electrónico inválido
 * y deshabilita el botón de suscripción.
 *
 * @function
 * @name validateEmailNLPopUp
 * @returns {void}
 */
function validateEmailNLPopUp() {
    const isEmailValid = emailValidation;
    const $invalidEmailElement = $('.invalid__email');
    const isPhoneEmpty = $('#hpPhoneSignUp').val().length === 0;

    if (isPhoneEmpty) {
        phoneValidation = undefined;
    }

    if (isEmailValid) {
        $invalidEmailElement.addClass('d-none');

        const isPhoneValid = phoneValidation === true;
        const isPhoneUndefined = phoneValidation === undefined;

        // Verificar las condiciones para habilitar o deshabilitar el botón de suscripción
        if (((whatsAppValidation || SMSValidation) && isPhoneValid) || (!(whatsAppValidation || SMSValidation) && (isPhoneUndefined || isPhoneValid))) {
            enableSubscribeButton();
        } else {
            disableSubscribeButton();
        }
    } else {
        // Si el correo electrónico no es válido, mostrar el mensaje de correo electrónico inválido
        $invalidEmailElement.removeClass('d-none');
        disableSubscribeButton();
    }
}

/**
 * Valida el estado del teléfono y realiza acciones correspondientes en función de su valor.
 * Si el teléfono es válido, verifica el estado de la validación del correo electrónico.
 * Si el teléfono es inválido, realiza validaciones adicionales para habilitar o deshabilitar el botón de suscripción.
 *
 * @function
 * @name validatePhone
 * @returns {void}
 */
function validatePhone() {
    const isPhoneValid = phoneValidation;
    const phoneEmpty = $('#hpPhoneSignUp').val().length === 0;
    const $invalidPhoneElement = $('.invalid__phone');

    if (isPhoneValid) {
        $invalidPhoneElement.addClass('d-none');

        if (emailValidation) {
            enableSubscribeButton();
        }
    } else {
        // Caso teléfono inválido
        if (!isPhoneValid && phoneEmpty && whatsAppValidation) {
            $invalidPhoneElement.removeClass('d-none');
            disableSubscribeButton();
            return;
        } else if (phoneEmpty) {
            $invalidPhoneElement.addClass('d-none');
        } else {
            $invalidPhoneElement.removeClass('d-none');
        }

        if (emailValidation && phoneEmpty && !whatsAppValidation) {
            enableSubscribeButton();
        } else {
            disableSubscribeButton();
        }
    }
}

/**
 * Valida el estado del checkbox WhatsApp y realiza acciones correspondientes en función de su valor.
 * Si WhatsApp está activado, verifica el estado de la validación del teléfono y del correo electrónico.
 * Si WhatsApp está desactivado, realiza validaciones adicionales para habilitar o deshabilitar el botón de suscripción.
 *
 * @function
 * @name validateWhatsApp
 * @author Eduardo González
 * @date 21/07/2023
 * @returns {void}
 */
function validateWhatsApp() {
    const $invalidPhoneElement = $('.invalid__phone');

    if ($('#whatsappCheck').prop('checked') || $('#smsCheck').prop('checked')) {
        // Caso WhatsApp activado
        if (!phoneValidation) {
            $invalidPhoneElement.removeClass('d-none');
            disableSubscribeButton();
        } else {
            $invalidPhoneElement.addClass('d-none');

            if (emailValidation) {
                enableSubscribeButton();
            }
        }
    } else { // Caso WhatsApp desactivado
        const isPhoneInvalid = !phoneValidation;
        const isPhoneEmpty = $('#hpPhoneSignUp').val().length === 0;
        const isPhoneNotEmpty = $('input[name=hpPhoneSignUp]').val().length !== 0;
        const isPhoneUndefined = phoneValidation === undefined;
        const isEmailInvalid = emailValidation === false;

        if ((isPhoneInvalid && isEmailInvalid) || (isEmailInvalid && isPhoneEmpty)
            || (emailValidation && isPhoneInvalid && isPhoneEmpty) || (emailValidation && isPhoneUndefined)) {
            $invalidPhoneElement.addClass('d-none');
        }

        if (emailValidation && (phoneValidation || isPhoneEmpty)) {
            enableSubscribeButton();
        } else if (isPhoneInvalid && isPhoneNotEmpty) {
            $invalidPhoneElement.removeClass('d-none');
        }
    }
}


/**
 * Valida el campo de correo electrónico y realiza acciones correspondientes en función de su valor.
 *
 * @function
 * @name handleEmailInputChange
 * @author Eduardo González
 * @date 21/07/2023
 * @returns {void}
 */
function handleEmailInputChange() {
    const emailValue = $(this).val();
    emailValidation = regexEmail.test(emailValue);
    validateEmailNLPopUp();
}

/**
 * Valida el campo de teléfono y realiza acciones correspondientes en función de su valor.
 *
 * @function
 * @name handlePhoneInputChange
 * @author Eduardo González
 * @date 21/07/2023
 * @returns {void}
 */
function handlePhoneInputChange() {
    const phoneValue = $(this).val();
    phoneValidation = regexPhone.test(phoneValue);
    validatePhone();
}

/**
 * Maneja el evento de cambio de entrada de teléfono.
 * Verifica si el checkbox está tildado o no y actualiza la variable whatsAppValidation.
 * Luego, llama a la función validateWhatsApp() para realizar validaciones adicionales.
 *
 * @function
 * @name handleWhatsappInputChange
 * @author Eduardo González
 * @date 21/07/2023
 * @returns {void}
 */
function handleWhatsappInputChange() {
    whatsAppValidation = $(this).prop('checked');
    validateWhatsApp();
}

/**
 * Maneja el evento de cambio de entrada de teléfono.
 * Verifica si el checkbox está tildado o no y actualiza la variable SMSValidation.
 * Luego, llama a la función validateWhatsApp() para realizar validaciones adicionales.
 *
 * @function
 * @name handleSMSInputChange
 * @author Cesar Hernandez
 * @date 31/07/2023
 * @returns {void}
 */
function handleSMSInputChange() {
    SMSValidation = $(this).prop('checked');
    validateWhatsApp();
}

/**
 * This method will take care of initiating the main functions for popUp behaviour.
 * PageDesigner
 * Agrega los event listeners para validar los campos del formulario de suscripción en el PopUp del NewsLetter.
 *
 * @function
 * @name eventListenerValidationsEmailForm
 * @returns {void}
 */
function eventListenerValidationsEmailForm() {
    disableSubscribeButton();

    const $inputEmail = $('#hpEmailSignUp');
    const $inputPhone = $('#hpPhoneSignUp');
    const $inputWhatsapp = $('#whatsappCheck');
    const $inputSMS = $('#smsCheck');

    $inputEmail.on('input', handleEmailInputChange);
    $inputPhone.on('input', handlePhoneInputChange);
    $inputWhatsapp.on('change', handleWhatsappInputChange);
    $inputSMS.on('change', handleSMSInputChange);
}

/**
 * OnInitPopup: This method will take care of initiating the main functions for popUp behaviour.
 * PageDesigner
 **/

function OnInitPopup() {
    let currentUrl = new URL(window.location.href);
    let popUP = document.getElementById('modalNewsLetter');
    let isInPageDesigner = currentUrl.pathname
        .toString()
        .includes('__SYSTEM__Page');
    if (!isInPageDesigner && popUP) {
        subscribePopUp();
        validationGetStatusPopup();
        customerNegation();
        resetEmailCloseSession();
        eventListenerValidationsEmailForm();
    }
}

module.exports = { OnInitPopup: OnInitPopup };
