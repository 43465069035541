'use strict';

// Product Promotion Slick
$('.popUpProductSlider').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
});

// Category Promotion Slick
$('.popUpCategorySlider').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
});

// Subcategory Promotion Slick
$('.popUpSubCategorySlider').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1
});

$(window).on('load', function () {
    setTimeout(() => {
        $('#productSlider, #categorySlider, #subCategorySlider, .container-outer-promotions').css(
            'opacity',
            '1'
        );
    }, 1000);
});

// Close modal PopUpPromotions
$('.closePopUp, .closePromotion').on('click', function () {
    $('.container-outer-promotions').addClass('d-none');
});

// DAB-4791 PopUp desaparece por 24hs (Aplica a cada promocion por separado: Producto/Categoria/Subctegoria)

// Hide modal PopUpPromotions
function hidePopupPromotions(containerPopUpType) {
    $(containerPopUpType).addClass('d-none');
}

// Show modal PopUpPromotions
function showPopupPromotions(containerPopUpType) {
    $(containerPopUpType).removeClass('d-none');
}

// Save the current date local storage
$('.closePopUpPromoCategory, .closePromotionPromoCategory').on('click', function saveCurrentDatePromoCategory() {
    let now = new Date();
    localStorage.setItem('lastVisitDatePromoCategory', now);
    let lastVisitPromoCategory = localStorage.getItem('lastVisitDatePromoCategory');
});

$('.closePopUpPromoProduct, .closePromotionPromoProduct').on('click', function saveCurrentDatePromoProduct() {
    let now = new Date();
    localStorage.setItem('lastVisitDatePromoProduct', now);
    let lastVisitPromoProduct = localStorage.getItem('lastVisitDatePromoProduct');
});

$('.closePopUpPromoSubCategory, .closePromotionPromoSubCategory').on('click', function saveCurrentDateSubCategory() {
    let now = new Date();
    localStorage.setItem('lastVisitDateSubCategory', now);
    let lastVisitSubCategory = localStorage.getItem('lastVisitDateSubCategory');
});

// Check if 24 hours have passed
function checkDate() {
    let lastVisitPromoCategory = localStorage.getItem('lastVisitDatePromoCategory');
    let lastVisitPromoProduct = localStorage.getItem('lastVisitDatePromoProduct');
    let lastVisitSubCategory = localStorage.getItem('lastVisitDateSubCategory');

    if (lastVisitPromoCategory) {
        let now = new Date();
        let diff = now - new Date(lastVisitPromoCategory);
        let hours = diff / 1000 / 60 / 60;
        if (hours >= 24) {
            // 24 hours or more have passed
            showPopupPromotions('.containerCategory');
        } else {
            // 24 hours have not passed
            hidePopupPromotions('.containerCategory');
        }
    }

    if (lastVisitPromoProduct) {
        let now = new Date();
        let diff = now - new Date(lastVisitPromoProduct);
        let hours = diff / 1000 / 60 / 60;
        if (hours >= 24) {
            // 24 hours or more have passed
            showPopupPromotions('.containerProduct');
        } else {
            // 24 hours have not passed
            hidePopupPromotions('.containerProduct');
        }
    }

    if (lastVisitSubCategory) {
        let now = new Date();
        let diff = now - new Date(lastVisitSubCategory);
        let hours = diff / 1000 / 60 / 60;
        if (hours >= 24) {
            // 24 hours or more have passed
            showPopupPromotions('.containerSubCategory');
        } else {
            // 24 hours have not passed
            hidePopupPromotions('.containerSubCategory');
        }
    }
}

$(document).ready(function () {
    checkDate();
});
