'use strict';

var scrollAnimate = require('./scrollAnimate');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    if ($('.email-signup-message').length === 0) {
        $('body').append(
            '<div class="email-signup-message"></div>'
        );
    }
    $('.email-signup-message')
        .append('<div class="email-signup-alert text-center ' + status + '">' + data.msg + '</div>');

    setTimeout(function () {
        $('.email-signup-message').remove();
    }, 3000);
}

module.exports = function () {
    $('.back-to-top').click(function () {
        scrollAnimate();
    });

    $('.subscribe-email').on('click', function (e) {
        e.preventDefault();
        var url = $(this).data('href');
        var button = $(this);
        var emailId = $('#NewsLetterHome').val();
        var phoneId = $('#NewsLetterHomePhone').val();
        var smsId = $('#smsCheckHome').is(':checked');
        var whatsappId = $('#whatsappCheckHome').is(':checked');
        $.spinner().start();
        $(this).attr('disabled', true);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                emailId: emailId,
                phoneId: phoneId,
                smsId: smsId,
                whatsappId: whatsappId
            },
            success: function (data) {
                displayMessage(data, button);
                $('#NewsLetterHome').val('');
                $('#NewsLetterHomePhone').val('');
                $('#smsCheckHome').prop('checked', false);
                $('#whatsappCheckHome').prop('checked', false);
            },
            error: function (err) {
                displayMessage(err, button);
            }
        });
    });

    window.onscroll = function () {
        let wcxChat = document.getElementsByClassName('styles_button_main__2rvIi');
        let wppIcon = document.getElementsByClassName('whatsapp_icon');
        let offsetHeightFooter = document.getElementsByClassName('footer-copy-row')[0].offsetHeight;

        if (wcxChat[0] && wppIcon[0]) {
            if (window.innerWidth > 991 && window.innerWidth <= 1440) {
                if (offsetHeightFooter >= 74 && offsetHeightFooter < 100) {
                    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                        wcxChat[0].style.bottom = '90px';
                        wppIcon[0].style.bottom = '90px';
                    } else {
                        wcxChat[0].style.bottom = '3px';
                        wppIcon[0].style.bottom = '3px';
                    }
                } else if (offsetHeightFooter >= 100 && offsetHeightFooter < 144) {
                    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                        wcxChat[0].style.bottom = '100px';
                        wppIcon[0].style.bottom = '105px';
                    } else {
                        wcxChat[0].style.bottom = '3px';
                        wppIcon[0].style.bottom = '3px';
                    }
                } else if (offsetHeightFooter >= 144) {
                    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                        wcxChat[0].style.bottom = '145px';
                        wppIcon[0].style.bottom = '145px';
                    } else {
                        wcxChat[0].style.bottom = '3px';
                        wppIcon[0].style.bottom = '3px';
                    }
                }
            } else if (window.innerWidth > 1440) {
                if (offsetHeightFooter >= 74) {
                    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                        wcxChat[0].style.bottom = '70px';
                        wppIcon[0].style.bottom = '80px';
                    } else {
                        wcxChat[0].style.bottom = '0px';
                        wppIcon[0].style.bottom = '5px';
                    }
                }
            }
        }
    };
};
