var init = false;

$(".openChart-btn").on("click", function () {
    if (!init) {
        setTimeout(() => {
            $("#carouselBikeSizeChart-desktop, #carouselBikeSizeChart-mobile").slick({
                dots: true,
                infinite: false,
                adaptiveHeight: true
            });
            $(".carousel-container").css("opacity", "1");
        }, 200);
        init = true;
    }
});

$(document).ready(function () {
    if ($('#isBicycle').val() == 'true' || $('#isHockeyBat').val() == 'true') {
        $('#szb-vfr-button, #szb-chart-button').addClass('d-none');
        $('.openChart-btn').removeClass('d-none');
    }else{
        $('.szb-vfr-button, #szb-chart-button').removeClass('d-none');
        $('.openChart-btn').addClass('d-none');
    }
})
