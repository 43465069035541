let viewMoreButton = document.createElement('button');
viewMoreButton.id = 'viewMoreImageGrid';
viewMoreButton.innerHTML = 'Quiero ver más <i class="fa fa-chevron-down view-more-i"></i>';
const images = document.querySelectorAll('.product-image-container-grid');
let toggleVar = false;

if (images.length > 4) {
    document.querySelector('.row.image-grid-row').appendChild(viewMoreButton);
}

images.forEach(function (image, index) {
    if (index > 3 && !toggleVar) {
        image.style.display = 'none';
    } else {
        image.style.display = 'block';
    }
});

viewMoreButton.addEventListener('click', function () {
    toggleVar = !toggleVar;
    images.forEach(function (image, index) {
        if (index > 3 && !toggleVar) {
            image.style.display = 'none';
        } else {
            image.style.display = 'block';
        }
    });

    if (toggleVar) {
        viewMoreButton.innerHTML = 'Quiero ver menos <i class="fa fa-chevron-up view-more-i"></i>';
    } else {
        viewMoreButton.innerHTML = 'Quiero ver más <i class="fa fa-chevron-down view-more-i"></i>';
    }
});
